/* iPad window container styles */
.scanning-container{
  display:flex;
  justify-content: center;
  gap:3rem;
  flex-wrap: wrap-reverse;
}
.ipad-window {
    width: 40%; 
    max-height:350px;
    background-color: transparent;
    border-radius: 5px;
    position: relative;
    box-shadow: 0 0 10px rgba(36, 36, 36, 0.5);
    border:1px solid grey;
  }
  .dots{
    margin: 1rem;
    display: flex;
    gap:0.5rem;
  }
  .dot{
    background-color: #9b9b9b;
    width: 10px;
    font-size: 10px;
    border-radius:50%;
    color:transparent;
  }
  .screen {
    width: 100%;
    max-height:270px;
    overflow: hidden;
    object-fit: contain;
    background-color: blanchedalmond;

  }
  
  .screen img {
     width: 100%;
     object-fit:fill;
  }
  
.scan-line {
  width: 100%;
  height: 2px;
  background-color: blue; 
  box-shadow: 0 10px 20px blue;
  position: absolute;
  top: 0;
  left: 0;
  animation: scan 5s linear infinite alternate;
}
.waiting-msg{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width :40%;
  color:white;
  animation: colorChange 2s linear infinite;
}
@keyframes colorChange {
  0%, 100% { color: grey; }
  50% { color: rgb(255, 255, 255); }
}

@keyframes scan {
  0% {
    top: 0; 
  }
  100% {
    top: calc(100%); 
  }
}

@media (max-width: 768px) {
  .screen {
        max-height:200px;
  }
}
@media (max-width: 500px) {
  .screen {
        max-height:150px;
  
  }
  .ipad-window {
    width: 70%; 
  }
  .waiting-msg{
    width :80%;
  }
}